import "./App.scss"
import { Route, BrowserRouter as Router, Routes, Navigate, Outlet } from "react-router-dom"
import Dashboard from "./pages/dashboard"
import PageNotFound from "./pages/404"
import MortuaryRegister from "./pages/mortuary-register"
import Login from "./pages/login"
import { useAppDispatch, useAppSelector } from "./redux/hook"
import { useEffect } from "react"
import { hydrateAuth } from "./redux/features/auth/auth.slice"
import { hydrateUsers } from "./redux/features/user-manager/user-manager-slice"
import { LOCAL_STORAGE_KEY } from "./app-constants"
import { hydratePreferences } from "./redux/features/preferences/preferences.slice"
import EftTransactions from "./pages/transactions-eft"
import EasypayTransactions from "./pages/transactions-easypay"
import Policies from "./pages/policies"
import FAQs from "./pages/faqs"
import UsersPage from "./pages/users"
import ProtectedLayout from "./layouts/protected.layout"
import Claims from "./pages/claims"
import ClaimDetails from "./pages/claims-details"
import CreateClaim from "./pages/claims-create"
import Settings from "./pages/settings"
import SignupRequests from "./pages/signup-requests"
import SignupRequestDetails from "./pages/signup-request-details"
import Documents from "./pages/documents"
import { hydrateClaims } from "./redux/features/claims/claims.slice"
import { hydrateDocuments } from "./redux/features/documents/documents.slice"
import { hydrateFAQs } from "./redux/features/faqs/faqs.slice"
import Graves from "./pages/graves"
import TermsOfService from "./pages/terms-of-service"
import TermsDetails from "./pages/terms-details"
import Funerals from "./pages/funerals"
import CreateFuneral from "./pages/funerals-create"
import EditFuneral from "./pages/funerals-edit"

const ProtectedRoute = ({ children, isAllowed, redirectPath = "/login" }: any) => {
	if (!isAllowed) {
		return <Navigate to={redirectPath} replace />
	}

	return children ? children : <Outlet />
}

function App() {
	const dispatch = useAppDispatch()
	const { user, signInRequired, isAdmin } = useAppSelector((state) => state.auth)

	useEffect(() => {
		const checkPersistedState = async () => {
			try {
				const localState = await localStorage.getItem(LOCAL_STORAGE_KEY)

				if (localState) {
					const persistedState = JSON.parse(`${localState}`)

					if (persistedState) {
						dispatch(hydrateAuth(persistedState.auth))
						dispatch(hydrateUsers(persistedState.users))
						dispatch(hydrateClaims(persistedState.claims))
						dispatch(hydrateFAQs(persistedState.faqs))
						dispatch(hydrateDocuments(persistedState.documents))
						dispatch(hydratePreferences(persistedState.preferences))
					}
				}
			} catch (e) {
				console.log(e)
			}
		}

		checkPersistedState()
	}, [dispatch])

	const isOnline = () => !!user && !signInRequired

	const isAdminOnline = () => !!user && isAdmin && !signInRequired

	return (
		<div className='App'>
			<Router>
				<Routes>
					{/* <Route path='/' element={<Navigate to='/login' replace />} /> */}
					<Route path='/' element={<Login />} />
					<Route path='/login' element={<Login />} />
					<Route element={<ProtectedRoute isAllowed={isOnline()} redirectPath='/login' />}>
						<Route
							path='/settings'
							element={
								<ProtectedLayout>
									<Settings />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/dashboard'
							element={
								<ProtectedLayout>
									<Dashboard />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/funerals'
							element={
								<ProtectedLayout>
									<Funerals />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/funerals/create'
							element={
								<ProtectedLayout>
									<CreateFuneral />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/funerals/edit'
							element={
								<ProtectedLayout>
									<EditFuneral />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/documents'
							element={
								<ProtectedLayout>
									<Documents />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/graves'
							element={
								<ProtectedLayout>
									<Graves />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/policies'
							element={
								<ProtectedLayout>
									<Policies />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/claims'
							element={
								<ProtectedLayout>
									<Claims />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/claims/:id'
							element={
								<ProtectedLayout>
									<ClaimDetails />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/claims/create'
							element={
								<ProtectedLayout>
									<CreateClaim />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/eft-transactions'
							element={
								<ProtectedLayout>
									<EftTransactions />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/easypay-transactions'
							element={
								<ProtectedLayout>
									<EasypayTransactions />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/signup-requests'
							element={
								<ProtectedLayout>
									<SignupRequests />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/signup-requests/:id'
							element={
								<ProtectedLayout>
									<SignupRequestDetails />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/mortuary-register'
							element={
								<ProtectedLayout>
									<MortuaryRegister />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/terms'
							element={
								<ProtectedLayout>
									<TermsOfService />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/terms/:id'
							element={
								<ProtectedLayout>
									<TermsDetails />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/faqs'
							element={
								<ProtectedLayout>
									<FAQs />
								</ProtectedLayout>
							}
						/>
						<Route
							path='/users'
							element={
								<ProtectedLayout>
									<UsersPage />
								</ProtectedLayout>
							}
						/>
					</Route>
					<Route path='*' element={<PageNotFound />} />
				</Routes>
			</Router>
		</div>
	)
}

export default App
